<template>
  <div class="card card--profile-tasks">
    <div class="card-body">
      <div
        v-b-toggle="`collapseTraces`"
        class="row"
      >
        <div class="col">
          <div class="clearfix">
            <h3 class="float-start">
              <a data-action="collapse"><i
                data-feather="pen-tool"
                class="w-icon-24"
              /> Traces</a>
            </h3>
            <span class="float-end">
              <span
                class="dis-ib"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Add trace"
              ><a
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasAddTrace"
                aria-controls="offcanvasAddTrace"
                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                @click="openAddTrace"
              ><i data-feather="plus" /></a></span>
              <span
                class="dis-ib"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="View all traces"
              ><a
                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                @click="$router.push({name: 'admin.traces.index'})"
              ><i data-feather="eye" /></a></span>
            </span>
          </div>
        </div>
      </div>
      <b-collapse
        :id="`collapseTraces`"
        :visible="!fields.find(e => e.name == 'Traces') || fields.find(e => e.name == 'Traces').visibility"
      >
        <hr>
        <div
          v-if="loading"
          class="loading position-relative"
        >
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
        <div
          v-else
          class="table-responsive-sm"
        >
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Date</th>
                <th>Content</th>
                <th>Typology</th>
                <th>Source</th>
                <th>File</th>
                <th>Created</th>
                <th class="text-end">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(trace, index) in traces"
                :key="index"
              >
                <td>{{ trace.date }}</td>
                <td><small v-html="limitText(trace.note)" /></td>
                <td>{{ trace.typology ? trace.typology.name : '-' }}</td>
                <td><a
                  href="#"
                  title="go to source"
                  @click="$router.push({name: trace.noteable_route, params: { id: trace.noteable_id }})"
                >{{ trace.noteable_name }}</a></td>
                <td v-if="trace.file">
                  <a
                    :href="trace.file.url"
                    target="_blank"
                    class="me-1"
                  >{{ trace.file.name }}</a>
                </td>
                <td v-else>
                  -
                </td>
                <td>{{ trace.created_by }}</td>
                <td class="text-end">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block">
                      <b-dropdown-item @click="openViewTrace(trace)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View trace
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item @click="openAddTrace(trace)">
                        <i
                          class="me-50"
                          data-feather="edit-2"
                        /> Edit trace
                      </b-dropdown-item>
                      <b-dropdown-item @click="traceShowDeleteModal(trace.id)">
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-collapse>
    </div>
    <ItemInfoSheet :table-id="tracesInfoSheet">
      <template #info-sheet-title>
        {{ itemInfoSheetTitle }}
      </template>
      <template
        #info-sheet
      >
        <template
          v-if="selectedInfoSheet == 'viewTrace'"
        >
          <div class="offcanvas-body">
            <div
              class="mb-1"
            >
              <p>Created<br>
                <strong>{{ selectedItem.user }} ({{ selectedItem.date }})</strong></p>
              <hr>
            </div>
            <div
              class="mb-1"
            >
              <p>Content<br>
                <strong v-html="selectedItem.note" /></p>
              <hr>
            </div>
            <div
              class="mb-1"
            >
              <p>Source<br>
                <strong>{{ selectedItem.noteable_name }}</strong></p>
              <hr>
            </div>
          </div>
        </template>
        <template
          v-else-if="selectedInfoSheet == 'addTrace'"
        >
          <div class="offcanvas-body">
            <div
              class="mb-1"
            >
              <label
                for="nameseniorcall"
                class="form-label"
              >Researcher</label>
              <v-select
                v-model="userDestination"
                label="name"
                :options="users"
                :get-option-key="option => option.id"
                :placeholder="'Type 3 or more characters to search...'"
                @search="onSearch({ name: $event })"
              />
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Section</label>
              <select
                id=""
                v-model="section"
                name=""
                class="form-select select2"
              >
                <option
                  value=""
                  selected
                >
                  Select section
                </option>
                <option value="profile">
                  Profile
                </option>
              </select>
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Text</label>
              <div id="full-wrapper">
                <div id="full-container">
                  <quill-editor
                    v-model="newTrace"
                  />
                </div>
              </div>
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Typology</label>
              <v-select
                v-model="type"
                label="name"
                :options="typologies"
                :get-option-key="option => option.id"
              />
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label line-break"
              >Attach file</label>
              <p v-if="filename">
                Uploaded file:
                <a
                  :href="filepath"
                  target="_blank"
                >{{ filename }}</a>
                <br>
                <a
                  class="text-danger"
                  @click="deleteFile"
                >
                  <i data-feather="trash" /> Delete file
                </a>
              </p>
              <div>
                <input
                  :ref="`trace-file`"
                  type="file"
                  @change="setFile()"
                >
              </div>
            </div>
          </div>
          <div class="offcanvas-footer mt-auto">
            <button
              type="button"
              class="btn btn-success mb-1 d-grid w-100"
              @click="sendTrace"
            >
              Add Trace
            </button>
          </div>
        </template>
      </template>
    </ItemInfoSheet>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import vSelect from 'vue-select'
import Vue from 'vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    ItemInfoSheet,
    vSelect,
    BCollapse,
  },
  data() {
    return {
      userId: this.$route.params.id,
      selectedItem: {},
      selectedInfoSheet: '',
      newTrace: '',
      userDestination: null,
      section: null,
      loading: true,
      itemInfoSheetTitle: '',
      tracesInfoSheet: 1,
      type: null,
      attachFile: null,
      selectedId: null,
      filename: null,
      filepath: null,
      deleteF: false,
    }
  },
  computed: {
    ...mapGetters({
      traces: 'users/traces',
      loggedUser: 'auth/admin',
      users: 'users/fakeUsers',
      typologies: 'traceTypologies/items',
      user: 'users/user',
      fields: 'modals/tableSettingsFields',
    }),
  },
  async mounted() {
    this.userDestination = this.user
    if (this.typologies.length === 0) {
      this.$store.dispatch('traceTypologies/fetch')
    }
    this.reloadPage()
  },
  methods: {
    async reloadPage() {
      this.loading = true
      if (this.userId) {
        await this.$store.dispatch('users/fetchUserTraces', this.userId)
      } else {
        await this.$store.dispatch('users/fetchUserTraces', this.user.id)
      }
      // await this.$store.dispatch('users/fetchUserTraces', this.loggedUser.id)
      this.loading = false
      // eslint-disable-next-line no-undef
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async traceShowDeleteModal(id) {
      try {
        const resp = await Vue.prototype.$http.delete(`${Vue.prototype.$groupUrl}/internal-notes/delete/${id}`)
        if (resp.status === 200) {
          Vue.swal('Personal note deleted successfully!', '', 'success')
          this.reloadPage()
        } else {
          Vue.swal('Error on deleting the note.', 'Please, try it again', 'error')
        }
      } catch (e) {
        Vue.swal('Error on deleting the note.', 'Please, try it again', 'error')
      }
    },
    openViewTrace(item) {
      this.$store.dispatch('modals/toggleTableClicked', this.tracesInfoSheet)
      this.selectedInfoSheet = 'viewTrace'
      this.itemInfoSheetTitle = 'View trace'
      this.selectedItem = item
      this.$store.dispatch('modals/toggleInfoItemSheet', true)

      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    },
    deleteFile() {
      this.filename = null
      this.filepath = null
      this.deleteF = true
      this.sendTrace()
    },
    openAddTrace(trace = null) {
      if (trace) {
        this.selectedId = trace.id
        this.newTrace = trace.note
        this.type = trace.typology
        this.section = 'profile'
        this.filename = trace.file ? trace.file.name : null
        this.filepath = trace.file ? trace.file.url : null
        this.itemInfoSheetTitle = 'Edit trace'
      } else {
        this.selectedId = null
        this.newTrace = ''
        this.type = null
        this.section = null
        this.itemInfoSheetTitle = 'Add trace'
        this.filename = null
        this.filepath = null
      }

      this.$store.dispatch('modals/toggleTableClicked', this.tracesInfoSheet)
      this.selectedInfoSheet = 'addTrace'
      this.$store.dispatch('modals/toggleInfoItemSheet', true)

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    limitText(val) {
      if (val && (val.length > 60)) {
        return (`${val.substring(0, 60).replace(/\s\S*$/, '')} ...`)
      }
      return val
    },
    async onSearch(search) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch('users/fetchFakeUsers', search.name)
      }
    },
    async sendTrace() {
      if (this.newTrace && this.section === 'profile' && this.userDestination) {
        const date = new Date()
        const note = {
          date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
          hour: `${date.getHours()}:${date.getMinutes()}`,
          trace: this.newTrace,
          user: { id: this.userDestination.id },
          url: this.$route.name,
          trace_typology_id: this.type ? this.type.id : null,
          file: this.attachFile,
          id: this.selectedId,
          delete_file: this.deleteF,
        }

        await this.$store.dispatch('users/fetchSendTrace', note)
        Vue.prototype.$saveFiles([this.attachFile])

        Vue.swal('Sended!', '', 'success').then(() => {
          this.$store.dispatch('modals/toggleInfoItemSheet', false)
          this.newTrace = null
          // this.userDestination = null
        })
        this.newNote = ''
        this.type = null
        this.attachFile = null
        this.deleteF = false
        this.reloadPage()
      } else {
        // Vue.swal('Make sure you fill in all the fields', '', 'warning')
        this.$toastr.warning('', 'Make sure you fill in all the fields')
      }
    },
    setFile() {
      const file = this.$refs['trace-file'].files[0]
      this.attachFile = {
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        file,
        name: file.name,
        original_name: file.name,
        url: URL.createObjectURL(file),
        description: '-',
        to: '',
      }
    },
  },
}
</script>

<style>

</style>
